import React, { useEffect, useState } from 'react'

import './PlanConstructor.css'

import { MAX_CHANNELS_QTY, periodVariants, planConstructorItemStructure } from '../utils/constants'
import { getChannelQty, getNoramlizedPlanItems } from '../utils'
import { channelNames } from '../../../utils/constants'

import PlanConstructorItem from '../PlanConstructorItem'
import Button from '../../../components/buttons/Button'
import Select from '../../../components/formControls/Select'

const PlanConstructor = ({ 
   isPlanActive,
   services,
   isCalculating,
   calculatedPrice, 
   shouldPay,
   calculationError,
   onPlanChange, 
   onConstructorSubmit
}) => {  
   const normalizedPlanItems = getNoramlizedPlanItems(services)
   const [planConstructorItems, setPlanConstructorItems] = useState(
      normalizedPlanItems.length 
         ? normalizedPlanItems 
         : [planConstructorItemStructure]
   )
   const [selectedPeriod, setSelectedPeriod] = useState(periodVariants[0])
   const [shouldCalculate, setShouldCalculate] = useState(true)
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const showPeriodsSelect = !isPlanActive || shouldPay
   const totalChannelsQty = planConstructorItems.reduce((acc, item) => acc += item.qty, 0)
   const disableIncreaseBtn = totalChannelsQty >= MAX_CHANNELS_QTY
   
   const handlePeriodSelect = (period) => setSelectedPeriod(period)

   const handleChannelSelect = (channel, id) => {
      setPlanConstructorItems(items => items.map(item => (
         item.id === id
            ? { ...item, selectedChannel: channel }
            : item
      )))
   }

   const handleQtyChange = (qty, id) => {
      setPlanConstructorItems(items => items.map(item => (
         item.id === id
            ? { ...item, qty }
            : item
      )))
   }

   const handleItemDelete = (id) => {
      setPlanConstructorItems(items => items.filter(item => item.id !== id))
   }

   const handleAddItem = () => {
      setPlanConstructorItems(items => {
         return [...items, { 
            ...planConstructorItemStructure, 
            id: items[items.length - 1].id + 1, 
            selectedChannel: {
                  id: items[0].selectedChannel.name === channelNames.WHATSAPP ? 1 : 2,
                  name: items[0].selectedChannel.name === channelNames.WHATSAPP 
                     ? channelNames.TELEGRAM 
                     : channelNames.WHATSAPP
               }
         }]
      })
   }

   const handleCunstructorSubmit = async () => {
      const prevWhatsappQty = getChannelQty(normalizedPlanItems, channelNames.WHATSAPP)
      const prevTelegramQty = getChannelQty(normalizedPlanItems, channelNames.TELEGRAM)
      const whatsappQty = getChannelQty(planConstructorItems, channelNames.WHATSAPP)
      const telegramQty = getChannelQty(planConstructorItems, channelNames.TELEGRAM)
      
      if (!shouldPay && prevWhatsappQty === whatsappQty && prevTelegramQty === telegramQty) return
      
      setDisableSubmitBtn(true)
      const data = {
         whatsapp: whatsappQty,
         telegram: telegramQty,
         periods: !shouldPay ? 0 : selectedPeriod.value
      }
      await onConstructorSubmit(data)
      setDisableSubmitBtn(false)
   }

   useEffect(() => {
      if (shouldCalculate) {
         onPlanChange({
            whatsapp: getChannelQty(planConstructorItems, channelNames.WHATSAPP),
            telegram: getChannelQty(planConstructorItems, channelNames.TELEGRAM),
            periods: isPlanActive ? 0 : selectedPeriod.value
         }, selectedPeriod.value)
      } else {
         setShouldCalculate(true)
      }
   }, [planConstructorItems, selectedPeriod])

   useEffect(() => {
      if (calculationError) {           
         setPlanConstructorItems(normalizedPlanItems)
         setShouldCalculate(false)
      }
   }, [calculationError])
   
   return (
      <div className='plan-constructor'>
         <div className='plan-constructor__container'>
            {showPeriodsSelect && (
               <div className='plan-constructor__period'>
                  <h4 className='plan-constructor__title'>
                     Срок тарифа
                  </h4>
                  <Select
                     className='plan-payment-page-constructor__select'
                     label='Количество периодов'
                     selectedValue={selectedPeriod}
                     values={periodVariants}
                     onSelect={handlePeriodSelect}
                  />
               </div>
            )}
            <div className='plan-constructor__constructor'>
               <h4 className='plan-constructor__title'>
                  Выбор тарифа
               </h4>
               <div className='plan-constructor__body'>
                  {planConstructorItems.map(item => (
                     <PlanConstructorItem
                        key={item.id}
                        planConstructorItems={planConstructorItems}
                        item={item}
                        disableDeleteBtn={planConstructorItems.length < 2}
                        disableIncreaseBtn={disableIncreaseBtn}
                        onChannelSelect={handleChannelSelect}
                        onQtyChange={handleQtyChange}
                        onDelete={handleItemDelete}
                     />
                  ))}
                  {planConstructorItems.length < 2 && (
                     <button 
                        className='plan-constructor__add-item-btn'
                        type='button'
                        onClick={handleAddItem}
                     >
                        Добавить канал
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M1 7H7M7 7H13M7 7V1M7 7V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                     </button>
                  )}
               </div>
            </div>
         </div>
         <div className='plan-constructor__total'>
            {isCalculating 
               ? <div className='total-price-loader'>
                  <div className='total-price-loader__loader'></div>
               </div>
               : <>
                  <h4 className='plan-constructor__title'>
                     Стоимость
                  </h4>
                  <ul className='plan-constructor__total-list'>
                     {planConstructorItems.map(item => (
                        <li
                           key={item.id}
                           className='plan-constructor__total-list-item'
                        >
                           <div className='plan-constructor__total-list-item-info'>
                              {`${item.selectedChannel.name} (${item.qty})`}
                           </div>
                        </li>
                     ))}
                  </ul>
                  <div className='plan-constructor__total-price'>
                     Итого:
                     <span>
                        {calculatedPrice + ' руб'}
                     </span>
                  </div>
                  <Button
                     text={!shouldPay ? 'Изменить тариф' : 'Оплатить'}
                     onClick={handleCunstructorSubmit}
                     disabled={disableSubmitBtn}
                  />
               </>
            }
         </div>
      </div>
   )
}

export default PlanConstructor
import { channelNamesMap } from "./constants"

export const getNoramlizedPlanItems = (items = []) => {
   const filteredItems = items.filter(item => item.quantity > 0)
   
   return filteredItems.map(item => ({
      id: item.service,
      selectedChannel: {
         id: item.service,
         name: channelNamesMap[item.service]
      },
      qty: item.quantity
   }))
}

export const getChannelQty = (items, channelName) => {
   return items.reduce((acc, item) => (
      item.selectedChannel.name === channelName
         ? acc + item.qty
         : acc
   ), 0)
}

export const getFormattedDate = (dateString) => {
   if (!dateString) return 'Тариф закончился'
   const date = new Date(dateString)
   const day = date.getDate()
   const month = date.getMonth() + 1
   const year = date.getFullYear()
   return `${day}.${month < 10 ? `0${month}` : month}.${year}`
}

export const getIsPlanActive = (planEndDate, planType) => {
   if (!planEndDate || !planType) return false
   const endDate = new Date(planEndDate)
   const currentDate = new Date()
   
   return Boolean(endDate > currentDate && planType !== 0)
}

export const getIsPlanEnded = (planEndDate) => {
   const endDate = new Date(planEndDate)
   const currentDate = new Date()
   
   return Boolean(endDate < currentDate)
}

export const getMaxForCreationChannelQty = (plan, channelType) => {
   if (!plan) return 0
   return plan.planServices.reduce((acc, service) => acc += service.service === channelType ? service.quantity : 0, 0)
}

export const getCurrentChannelQty = (channels, channelType) => {
   return channels.reduce((acc, channel) => acc += channel.channel.type === channelType ? 1 : 0, 0)
}
import PlanService from "../../../services/planService"
import { 
   changePlanSuccess, 
   fetchPlanFinished, 
   fetchPlanStarted, 
   fetchPlanSuccess,
   setCalculationError
} from "."
import { changeUserPlanSuccess, updateUserSuccess } from "../auth"

export const updateAutoPay = (body) => async (dispatch) => {
   try {
      await PlanService.updateAutoPay(body)
      dispatch(updateUserSuccess(body))
   } catch (err) {
      console.log(err)
   }
}

export const redirectToPaymentPage = (body) => async (dispatch) => {
   try {
      await PlanService.redirectToPaymentPage(body)
   } catch (err) {
      console.log(err)
   }
}

export const fetchPlan = () => async (dispatch) => {   
   try {
      dispatch(fetchPlanStarted())
      const data = await PlanService.fetchPlan()
      const plan = data.data
      
      dispatch(fetchPlanSuccess(Array.isArray(plan) ? {} : plan))
   } catch (err) {
      alert('Не удалось получить данные о тарифе. Попробуйте позднее.')
   } finally {
      dispatch(fetchPlanFinished())
   }
}

export const calculatePlanPrice = (body) => async (dispatch) => {
   dispatch(setCalculationError(false))
   try {
      const data = await PlanService.calculatePlanPrice(body)
      return data.data
   } catch (err) {
      alert('Произошла ошибка при подсчете стоимости тарифа. Попробуйте позднее.')
      dispatch(setCalculationError(true))
      return Promise.reject(err)
   }
}

export const createPayment = (body) => async (dispatch) => {
   try {
      const data = await PlanService.createPayment(body)
      
      if (data.data.confirmationUrl) {
         dispatch(redirectToPaymentPage(data.data.confirmationUrl))
      } else {
         dispatch(changePlanSuccess(data.data))
         dispatch(changeUserPlanSuccess(data.data.services))
      }
   } catch (err) {
      alert('Произошла ошибка. Попробуйте позднее.')
   }
}
import $axios from "../http"

class CrmsService {
   static fetchChannels = async (id) => {
      const { data } = await $axios.get(`target-channel/get${id ? `/${id}` : ''}`)
      return data
   }

   static createAmoChannel = async () => {
      const { data } = await $axios.post('amo/oauth')
      return data
   }

   static setAmoChannel = async (body) => {
      const { data } = await $axios.post('amo/set', body)
      return data
   }

   static createBitrixChannel = async ({ username }) => {
      const { data } = await $axios.get(`bitrix/oauth?username=${username}`)
      return data
   }

   static setBitrixChannel = async (body) => {
      const { data } = await $axios.post('bitrix/set', body)
      return data
   }

   static updateChannel = async ({ id, body }) => {      
      const { data } = await $axios.patch(`target-channel/change/${id}`, body)
      return data
   }

   static deleteChannel = async (id) => {
      const { data } = await $axios.delete(`target-channel/delete/${id}`)
      return data
   }
}

export default CrmsService
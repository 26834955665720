import React, { useState } from 'react'
import './ChannelsList.css'
import Toggle from '../../../components/formControls/Toggle'

const channelsMap = {
   whatsapp: {
      channelIcon: '/assets/icons/whatsapp-icon.svg',
      channelName: 'Whatsapp'
   },
   telegram: {
      channelIcon: '/assets/icons/telegram-icon.svg',
      channelName: 'Telegram'
   }
}

const ChannelsListItem = ({ channelData, onStatusChange, onDeleteBtnClick }) => {
   const { id, channel, phone, status } = channelData
   const isActive = Boolean(status)
   const { channelIcon, channelName } = channelsMap[channel.type]

   const [disableToggle, setDisableToggle] = useState(false)

   const handleStatusChange = async () => {
      setDisableToggle(true)
      const data = {
         id, 
         phone,
         channel: channel.type
      }
      await onStatusChange(data, isActive)
      setDisableToggle(false)
   }

   const handleDeleteBtnClick = () => {
      onDeleteBtnClick(id)
   }

   return (
      <li className='channels-list-item'>
         <div className='channels-list__column channels-list__column_channel'>
            <div className='channels-list-item__channel'>
               <img src={channelIcon} alt="" />
               <span>{channelName}</span>
            </div>
         </div>
         <div className='channels-list__column channels-list__column_phone'>
            <span className='channels-list-item__phone'>{phone}</span>
         </div>
         <div className='channels-list__column channels-list__column_status'>
            <Toggle
               text={isActive? 'Активный' : 'Неактивый'}
               checked={isActive}
               disabled={disableToggle}
               onChange={handleStatusChange}
            />
         </div>
         <div className='channels-list__column channels-list__column_actions'>
            <button 
               className='channels-list-item__delete-btn'
               onClick={handleDeleteBtnClick}
            >
               <img src='/assets/icons/delete-icon.svg' alt='Delete' />
            </button>
         </div>
      </li>
   )
}

export default ChannelsListItem